import {
  FaChartArea,
  FaChartBar,
  FaDesktop,
  FaHandsHelping,
  FaHeadset,
  FaHdd,
  FaLock,
  FaMoneyBill,
  FaMoneyCheck,
  FaNetworkWired,
  FaServer,
  FaUserAlt,
} from "@onemind-services-llc/react-icons-ng/fa";

export const FEATURE_CONSTANTS = {TITLE: "Why Choose Onemind Services?"};

export const LIST1 = [
  {content: "Economy of Scale", icon: FaChartArea},
  {content: "Licensing for Various Vendors", icon: FaMoneyCheck},
  {content: "Any Technology, Vendor and Platform", icon: FaNetworkWired},
  {content: "99.99% SLA", icon: FaServer},
  {content: "Highly Secured", icon: FaLock},
  {content: "Customized Golden Images", icon: FaDesktop},
];

export const LIST2 = [
  {content: "1:1 Instance with Zero Downtime ", icon: FaChartBar},
  {content: "24/7/365 Priority Support ", icon: FaHeadset},
  {content: "No Special Hardware or Software Needed", icon: FaHdd},
  {content: "No Expensive Hardware or Maintenance", icon: FaMoneyBill},
  {content: "Special Partner Pricing ", icon: FaUserAlt},
  {content: "No Long Term Contract", icon: FaHandsHelping},
];
