import React from "react";
import {Stack} from "@chakra-ui/react";
import ListLayout from "@/components/listLayout";
import Title from "@/components/title";
import {FEATURE_CONSTANTS, LIST1, LIST2} from "./features.constants";

const Features = () => {
  return (
    <Stack spacing={8}>
      <Title highlight={"Services"} title={FEATURE_CONSTANTS.TITLE} />
      <ListLayout image="/features/rocket.svg" list1={LIST1} list2={LIST2} />
    </Stack>
  );
};

export default Features;
